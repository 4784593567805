import { Enums } from 'services/dropdown-enums';
import ChatHistory from 'pages/portal/messaging/History/chatHistory';
import storage from 'services/utils/storage';
import {
  appointmentTypeColumns,
  callOperatorColumns,
  ChatColumns,
  CompaniesColumns,
  CallRecordingColumns,
  ContractSigningColumns,
  companyRepsColumns,
  companyUserColumns,
  LeadsColumns,
  MyReportsColumns,
  operatorsColumns,
  ProvisionColumns,
  QueReportsColumns,
  salesRepColumns,
  schedulingPageColumns,
  ssAppointmentTypeColumns,
  userManagementColumns
} from './tableColumns';
import { camelize } from '../generic.methods';

const defaultVisibleCols = {
  // companies default visible columns
  companies: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'companyName',
      'companyKey',
      'active',
      'hostName',
      'hasInvalidEmails'
    ]
  },
  chats: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'pickedUpByAgentUsername',
      'createdOn',
      'modifiedOn',
      'assignedToAgentUsername',
      'initiatedBy',
      'isBlank',
      'appointmentStatus'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'createdOn',
      'assignedToAgentUsername',
      'isLead',
      'leadType'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'createdOn',
      'isLead',
      'leadType',
      'appointmentStatus'
    ]
  },
  leads: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'name',
      'phone',
      'email',
      'createdOn',
      'leadType',
      'initiatedBy',
      'hostName',
      'appointmentStatus'
    ],
    [Enums.Relationship.Partner]: [
      'id',
      'companyName',
      'companyKey',
      'name',
      'phone',
      'email',
      'processedByCustomer',
      'createdOn',
      'leadType',
      'initiatedBy',
      'leadCategoryName',
      'hostName'
    ],
    [Enums.Relationship.Customer]: [
      'id',
      'name',
      'phone',
      'email',
      'processedByCustomer',
      'createdOn',
      'leadType',
      'initiatedBy',
      'leadCategoryName',
      'appointmentStatus'
    ]
  },
  widgetCompanies: {
    [Enums.Relationship.Administrator]: [
      'id',
      'companyName',
      'companyKey',
      'active'
    ],
    [Enums.Relationship.Partner]: ['id', 'companyName', 'companyKey', 'active'],
    [Enums.Relationship.Customer]: ['id', 'companyName', 'companyKey', 'active']
  }
};

const generateActionButtons = (Comp, name = null) => ({
  name: camelize(name) || 'actions',
  header: name || 'Action',
  minWidth: 130,
  defaultFlex: 2,
  sortable: false,
  visible: true,
  render: value => {
    return (
      <div className="d-flex justify-content-end mr-2  grid-action-mn">
        {Comp ? <Comp value={value.data} /> : <></>}
      </div>
    );
  }
});

// visible columns by relation
const visibleByRelation = (
  columns,
  relation,
  forGrid = '',
  showAll = false
) => {
  let visibleCols = defaultVisibleCols?.[forGrid]?.[relation] || [];

  let updatedCols = columns.map(col => ({
    ...col,
    visible: visibleCols.includes(col.name) || showAll ? true : false
  }));

  return updatedCols;
};

// companies columns config
export const companiesTableConfig = (canRequestLead, Comp, filter = true) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [
    ...CompaniesColumns(CompanyRelationship, canRequestLead)
  ].filter(col => (filter ? col.isEnable : true));

  // set visibleColums
  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companies',
    CompanyRelationship === Enums.Relationship.Customer
  );

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// Voice table columns config
export const callRecordingTableConfig = (
  Comp,
  compName,
  Comp2,
  comp2Name,
  filter = true
) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...CallRecordingColumns(CompanyRelationship)].filter(col =>
    filter ? col.isEnable : true
  );

  // set visibleColums
  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companies',
    CompanyRelationship === Enums.Relationship.Customer
  );

  // add action Buttons
  if (Comp) columns.splice(8, 0, generateActionButtons(Comp, compName));
  if (Comp2) columns.push(generateActionButtons(Comp2, comp2Name));

  return columns;
};

// Contract signing table columns config
export const contractSigningTableConfig = (
  canRequestLead,
  Comp,
  filter = true
) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [
    ...ContractSigningColumns(CompanyRelationship, canRequestLead)
  ].filter(col => (filter ? col.isEnable : true));

  // set visibleColums
  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companies',
    CompanyRelationship === Enums.Relationship.Customer
  );

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// chat columns config
export const chatTableConfig = (
  operatorsEnabled = false,
  isAppointment = false,
  addChatSourceToLead = false,
  Comp = null,
  filter = true
) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [
    ...ChatColumns(
      CompanyRelationship,
      isAppointment,
      operatorsEnabled,
      addChatSourceToLead
    )
  ].filter(col => col.isEnable);

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'chats');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// lead columns config
export const leadsTableConfig = (
  isEncrypt = false,
  isAppointment = false,
  locationEnabled = false,
  addChatSourceToLead = false,
  Comp
) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [
    ...LeadsColumns(
      CompanyRelationship,
      isEncrypt,
      isAppointment,
      locationEnabled,
      addChatSourceToLead
    )
  ].filter(col => col.isEnable);

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'leads');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// widget setting companies columns config
export const widgetCompaniesTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...CompaniesColumns(CompanyRelationship)].filter(
    col => col.isEnable
  );

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'companies');

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

export const historyTableConfig = [
  {
    name: 'companyName',
    header: '',
    defaultFlex: 1,
    // defaultWidth: 440,
    filterDelay: 800,
    // minWidth: 280,
    enableFilter: true,
    filterType: 'string',
    defaultVissible: true,
    className: 'hide-filter-button',
    render: ({ value }) => <ChatHistory companyName={value} />
  }
];

// provision columns config
export const provisionTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...ProvisionColumns(CompanyRelationship)];

  // set visibleColums
  columns = visibleByRelation(columns, CompanyRelationship, 'provision', true);

  // add action Buttons
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// my reports columns config
export const myReportTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...MyReportsColumns(CompanyRelationship)];

  columns = visibleByRelation(columns, CompanyRelationship, 'myReports', true);
  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// operators columns config
export const operatorTableConfig = callback => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...operatorsColumns(CompanyRelationship, callback)];

  columns = visibleByRelation(columns, CompanyRelationship, 'operators', true);
  return columns;
};

//call operator columns config
export const callOperatorTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...callOperatorColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'callOperators',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

//call operator columns config
export const appointmentTypeTableConfig = (Comp, appointmentType) => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [
    ...appointmentTypeColumns(CompanyRelationship, appointmentType)
  ];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'appointmentType',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

//call operator columns config
export const ssAppointmentTypeTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...ssAppointmentTypeColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'ssAppointmentType',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

//sales rep columns config
export const schedulingPageTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...schedulingPageColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'schedulingPage',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

//sales rep columns config
export const companyRepsTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...companyRepsColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companyReps',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

//sales rep columns config
export const salesRepTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...salesRepColumns(CompanyRelationship)];

  columns = visibleByRelation(columns, CompanyRelationship, 'salesRep', true);

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// que reports columns config
export const queuedReportTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...QueReportsColumns(CompanyRelationship)];

  columns = visibleByRelation(columns, CompanyRelationship, 'queReports', true);

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

export const usersTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...companyUserColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companyUsers',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

export const userManagementTableConfig = Comp => {
  const { CompanyRelationship } = JSON.parse(storage.getItem('Profile')) || {};

  // filter columns by isEnable || relation
  let columns = [...userManagementColumns(CompanyRelationship)];

  columns = visibleByRelation(
    columns,
    CompanyRelationship,
    'companyUsers',
    true
  );

  if (Comp) columns.push(generateActionButtons(Comp));

  return columns;
};

// export const getKeysByFilterType = (fields = [], type = '') => {
//   fields.filter(c => c.filterType === type).map(c => c.name);
// };
